import { alertController } from '@ionic/core';
import { createSlice } from '@reduxjs/toolkit'

export const newSolicitationSlice = createSlice({
  name: 'newSolicitation',
  initialState: {
    value: {
      returned_id: null,
      Street: "",
      StreetLabel: "",
      Neighborhood: "",
      NeighborhoodLabel: "",
      Number: "",
      Reference: "",
      Coords: "",
      Photo: "",
      PhotoName: "",
      PhotoBlob: "",
      Description: "",
      Subject:"",
      SubjectDetails: {},

      systemOwnerFrom: "",
      protocol: "",
      requestId: "",
      manifestationId: "",
      requestType: "",
      requestLocal: "",
      message: "",
      attachment1: "",
      attachment2: "",
      attachment3: "",
      attachment1FileName: "",
      attachment2FileName: "",
      attachment3FileName: "",
      identified: "True",

      appointmentIsForCurrentUser: "True",
      appointmentCep: "",
      appointmentName: "",
      appointmentCpf: "",
      appointmentService: "",
      appointmentArea: "",
      appointmentDate: "",
      appointmentTime: "",
      appointmentDateInput: [],
      appointmentTimeInput: [],
      appointmentOptions: [],
      reserved: false,
    },
    reverseGeocodedNumber: 0,
    switchGpsActivated: true,
    status: "",
  },
  reducers: {
    cleanNewSolicitation: (state, action) => {
      state.value = {
        returned_id: null,
        Street: "",
        StreetLabel: "",
        Neighborhood: "",
        NeighborhoodLabel: "",
        Number: "",
        Reference: "",
        Coords: "",
        Photo: "",
        PhotoName: "",
        PhotoBlob: "",
        Description: "",

        systemOwnerFrom: "",
        protocol: "",
        requestId: "",
        manifestationId: "",
        requestType: "",
        requestLocal: "",
        message: "",
        attachment1: "",
        attachment2: "",
        attachment3: "",
        attachment1FileName: "",
        attachment2FileName: "",
        attachment3FileName: "",
        identified: "True",

        appointmentIsForCurrentUser: "True",
        appointmentCep: "",
        appointmentName: "",
        appointmentCpf: "",
        appointmentService: "",
        appointmentArea: "",
        appointmentDate: "",
        appointmentTime: "",
        appointmentDateInput: [],
        appointmentTimeInput: [],
        appointmentOptions: [],
        reserved: false,

        Subject: "",
        IdCompany: "",
        IdCompanyFather: "",
        SubPrefLocated:true,
        SubjectDetails:{},
      };

      state.reverseGeocodedNumber = 0;
      state.switchGpsActivated = true;
      state.status = "";
    },
    setStreetToSolicitation: (state, action) => {
      let street = action.payload && action.payload.Street;
      let streetLabel = action.payload && action.payload.StreetLabel;
      state.value = {
        ...state.value,
        Street: street || "",
        StreetLabel: streetLabel || "",
      };
    },
    setNeighborhoodToSolicitation: (state, action) => {
      let neighborhood = action.payload && action.payload.Neighborhood;
      let neighborhoodLabel = action.payload && action.payload.NeighborhoodLabel;
      state.value = {
        ...state.value,
        Neighborhood: neighborhood || "",
        NeighborhoodLabel: neighborhoodLabel || "",
      };
    },
    setNumberToSolicitation: (state, action) => {
      let number = action.payload && action.payload.Number;
      state.value = {
        ...state.value,
        Number: number || "",
      };
    },
    setReferenceToSolicitation: (state, action) => {
      let reference = action.payload && action.payload.Reference;
      state.value = {
        ...state.value,
        Reference: reference || "",
      };
    },
    setCoordsToSolicitation: (state, action) => {
      let coords = action.payload && action.payload.Coords;
      state.value = {
        ...state.value,
        Coords: coords || "",
      };
    },
    setPhotoToSolicitation: (state, action) => {
      let photo = action.payload && action.payload.Photo;
      let photoName = action.payload && action.payload.PhotoName;
      let photoBlob = action.payload && action.payload.PhotoBlob;
      state.value = {
        ...state.value,
        Photo: photo || "",
        PhotoName: photoName || "",
        PhotoBlob: photoBlob || ""
      };
    },
    setDescriptionToSolicitation: (state, action) => {
      let description = action.payload && action.payload.Description;
      state.value = {
        ...state.value,
        Description: description || "",
      };
    },
    setSubjectToSolicitation: (state, action) => {
      let subject = action.payload && action.payload.Subject;
      let idCompany = action.payload && action.payload.IdCompany;
      let idCompanyFather = action.payload && action.payload.IdCompanyFather;
      let subPrefLocated = action.payload && action.payload.SubPrefLocated;
      state.value = {
        ...state.value,
        Subject: subject || "",
        IdCompany: idCompany || "",
        IdCompanyFather: idCompanyFather || "",
        SubPrefLocated: subPrefLocated || true,
      };
    },
    setSubjectDetailsToSolicitation: (state, action) => {
      let subjectDetails = action.payload && action.payload.SubjectDetails;
      state.value = {
        ...state.value,
        SubjectDetails: subjectDetails || {},
      };
    },
    setReverseGeocodedNumber: (state, action) => {
      let reverseGeocodeNumber = action.payload && action.payload.reverseGeocodedNumber;
      let results = (reverseGeocodeNumber || "").toString();
      state.reverseGeocodedNumber = results;
      if(!state.value.Number) {
        state.value = {
          ...state.value,
          Number: results || "",
        };
      }
    },

    setStatus: (state, action) => {
      let results = (action.payload || "notSent").toString();
      state.status = results;
    },
    setSwitchGps: (state, action) => {
      let results = action.payload || false;
      state.switchGpsActivated = results;
    },

    setRequestData: (state, action) => {
      let requestId = (action.payload.requestId || "").toString();
      let requestType = (action.payload.requestType || "").toString();
      let requestLocal = (action.payload.requestLocal || "").toString();
      let manifestationId = (action.payload.manifestationId || "").toString();
      let systemOwnerFrom = (action.payload.systemOwnerFrom || "").toString();
      let protocol = (action.payload.protocol || "").toString();
      let subject = (action.payload.subject || "").toString();

      state.value = {
        ...state.value,
        systemOwnerFrom: systemOwnerFrom,
        protocol: protocol,
        requestId: requestId,
        manifestationId: manifestationId,
        requestType: requestType,
        requestLocal: requestLocal,
        Subject: subject
      };
    },

    setIdentified: (state, action) => {
      state.value = {
        ...state.value,
        identified: action.payload.identified || "True"
      };
    },

    setMessage: (state, action) => {
      state.value = {
        ...state.value,
        message: action.payload.message || ""
      };
    },

    setAttachment: (state, action) => {
      state.value = {
        ...state.value,
        attachment1: action.payload.attachment1 || "",
        attachment2: action.payload.attachment2 || "",
        attachment3: action.payload.attachment3 || "",
        attachment1FileName: action.payload.attachment1FileName || "",
        attachment2FileName: action.payload.attachment2FileName || "",
        attachment3FileName: action.payload.attachment3FileName || "",
      };
    },

    setSubjectManifestation: (state, action) => {
      let subject = action.payload && action.payload.Subject;
      state.value = {
        ...state.value,
        Subject: subject || "",
      };
    },

    setSubjectDetailsManifestation: (state, action) => {
      let subjectDetails = action.payload && action.payload.SubjectDetails;
      state.value = {
        ...state.value,
        SubjectDetails: subjectDetails || {},
      };
    },

    setSolicitation: (state, action) => {
      let payload = action.payload;
      let curretValues = state.value;
      state.value = {
        ...curretValues,
        ...payload,
      };
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  cleanNewSolicitation,
  setSolicitation,
  setStreetToSolicitation,
  setNeighborhoodToSolicitation,
  setNumberToSolicitation,
  setReferenceToSolicitation,
  setCoordsToSolicitation,
  setPhotoToSolicitation,
  setSubjectToSolicitation,
  setSubjectDetailsToSolicitation,
  setDescriptionToSolicitation,
  setReverseGeocodedNumber,
  setStatus,
  setSwitchGps,
  setRequestData,
  setIdentified,
  setMessage,
  setAttachment,
  setSubjectManifestation,
  setSubjectDetailsManifestation
} = newSolicitationSlice.actions
export default newSolicitationSlice.reducer