import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToastBox } from '../data/toastBoxSlice';

const ToastBox = (props) => {
    const dispatch = useDispatch();
    const stateClassName = useSelector((state) => state.toastBox.status);
    const topClass = props.topClass || "toast-top";
    const text = props.text;
    const callback = props.callback || (() => {});
    const classes = [stateClassName, topClass].join(' ');

    const defaultHandleClick = () => {
        dispatch(setToastBox("not-show"));
    }

    const handleClick = () => {
        Promise.resolve(callback()).finally(defaultHandleClick());
    }

    return(
        <div className={"toast-box "+classes} onClick={handleClick} style={props.style}>
            <div className="in">
                <div className="text">
                    {text}
                </div>
            </div>
            <button type="button" className="btn btn-sm btn-text-light close-button">OK</button>
        </div>
    );
}

export default ToastBox;