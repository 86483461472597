import * as React from 'react';
import  { Redirect } from 'react-router-dom';
import Loader from '../Loader';
import OidcSettings from '../../configs/Oidcsettings';
import Settings from '../../configs/Settings';
import { useSelector } from 'react-redux';

const CustomOffLineCallback = () => {
    const settings = useSelector(state => state.settings.value);
    const oidcSettings = OidcSettings(settings);
    const url = oidcSettings.authority+"/Account/Logout?showLogoutPrompt=False&postLogoutRedirectUri="+Settings.FullBaseUrl;
    window.location.replace(url);

    return(
    <>
        <Loader type="dots"></Loader>
        <Redirect to='/'/>
    </>);
};

export default CustomOffLineCallback;