import { createSlice } from '@reduxjs/toolkit'

export const streetListSlice = createSlice({
  name: 'streetList',
  initialState: {
    value: [],
    available: [],
    filtering: false
  },
  reducers: {
    loadStreetList: (state, action) => {
     // debugger;;
      let results = action.payload || [];

      results.forEach(element => {
        let neighborhoods = element && element.neighborhoods || [];
        neighborhoods.forEach(neighborhood => {
          neighborhood.value = neighborhood.id;
          neighborhood.label = neighborhood.name;
        });

        element.value = element.id;
        element.label = element.name;
        element.neighborhoods = neighborhoods;
      });

      state.value = results;
    },

    setAvailableStreets: (state, action) => {
      //debugger;;
      let results = action.payload || state.value || [];
      let available = []
      results.forEach(element => {
        let neighborhoods = element.neighborhoods || [];
        neighborhoods.forEach(neighborhood => {
          neighborhood.value = neighborhood.id;
          neighborhood.label = neighborhood.name;
        });
        available.push({
          value: element.id,
          label: element.name,
          neighborhoods: neighborhoods
        });
      });

      state.filtering = action.payload ? true : false;
      state.available = available;
    }
  },
})

// Action creators are generated for each case reducer function
export const { loadStreetList, setAvailableStreets } = streetListSlice.actions

export default streetListSlice.reducer