
import React from 'react';
import ToastBox from './ToastBox';
import './Loader.css'

const Loader = (props) => {
    const toast = props.useToast ? <ToastBox text="Verifique a sua conexão com a internet" callback={()=>{window.location.reload(true);}} topClass="toast-top-default"/> : <></>;

    return (
        <div id="loader">
            {toast}
            {   props.type === "dots" ? (
                <img src="/assets/img/icon/icons-splash.png" alt="icon" className="loading-icon"/>
            ) : (
                <div className="loaderApp"></div>
            )}
        </div>
    );
}

export default Loader;