import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    value: {},
    fazenda:false,
  },
  reducers: {
    loadsettings: (state, action) => {
      state.value = action.payload || {};
    },
    freeFazenda: (state, action) => {
      state.fazenda = action.payload || false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { loadsettings, freeFazenda } = settingsSlice.actions

export default settingsSlice.reducer