const baseUrl = window.location.hostname || '';
const fullBaseUrl = window.location.origin || '';
const apiUrl = fullBaseUrl;

const hideChristmas = () => (new Date()).getFullYear() >= 2024;

const getEnvironment = () => {
    let hostname = window.location.hostname.toString().toLowerCase() || '';

    switch(hostname){
        case "joinvillefacil.pmjlle.joinville.sc.gov.br":
            return "staging";
        case "faciltreinamento.joinville.sc.gov.br":
            return "training";
        case "facil.joinville.sc.gov.br":
            return "production";
        default:
            if(hostname.indexOf("pmjlle.joinville.sc.gov.br") > 0) {
                return "development"
            }
            break;
    }

    return "";
}

const getGoogleAnalyticsId = () => {
    let hostname = window.location.hostname.toString().toLowerCase() || '';

    switch(hostname){
        case "faciltreinamento.joinville.sc.gov.br":
            return "G-JC4X5L969K";
        case "facil.joinville.sc.gov.br":
            return "G-ZHQZL6YEMT";
        default:
            break;
    }

    return "";
}

const getAppointmentApiUrl = () => {
    const hostname = window.location.hostname.toString().toLowerCase() || '';
    const protocol = window.location.protocol;

    switch (hostname) {
        case "joinvillefacil.pmjlle.joinville.sc.gov.br":
            return "https://centralagendamentoapi.pmjlle.joinville.sc.gov.br";
        case "faciltreinamento.joinville.sc.gov.br":
            return "https://agendamentoapi.joinville.sc.gov.br";
        case "facil.joinville.sc.gov.br":
            return "https://agendamentoapi.joinville.sc.gov.br";
        default:
            if (process.env.APPOINTMENT_API) {
                return process.env.APPOINTMENT_API;
            }

            if (hostname.indexOf("pmjlle.joinville.sc.gov.br") > 0) {
                return protocol+"//"+hostname + ":10060"
            }
            break;
    }

    return "";
}

const googleAnalyticsId = getGoogleAnalyticsId();

const Settings = {
    ApiUrl: apiUrl,
    BaseUrl: baseUrl,
    FullBaseUrl: fullBaseUrl,
    googleAnalyticsId: googleAnalyticsId,
};

export default Settings;
export { getEnvironment, getGoogleAnalyticsId, getAppointmentApiUrl, hideChristmas };