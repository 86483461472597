import { createSlice } from '@reduxjs/toolkit'

export const deviceKeySlice = createSlice({
  name: 'deviceKey',
  initialState: {
    value: '',
    sent: false
  },
  reducers: {
    setDeviceKey: (state, action) => {
      state.value =  action.payload.value || '';
    },
    setDeviceKeySent: (state, action) => {
      state.sent =  action.payload.value || false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDeviceKey, setDeviceKeySent } = deviceKeySlice.actions
export default deviceKeySlice.reducer