import React from "react";
import { getEnvironment } from "../configs/Settings";

const EnvironmentAlert = () => {
    const environment = getEnvironment();

    if(environment === "production"){
        return(<></>);
    }

    return(
        <div className="bg-danger appHeader" style={{
            zIndex: "1000 !important",
            height: "15px"
        }}>
            Você está no ambiente: {environment}
        </div>
    );
}

export default EnvironmentAlert;