import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    value: {
      token: "",
      sub: "",
      name: "",
      permissions: [],
      offices: [],
      email: "",
      mobile: "",
      phone: ""
    }
  },
  reducers: {
    setUserData: (state, action) => {
      state.value.sub = (action.payload.sub || "").toString();
      state.value.name = (action.payload.name || "").toString();
      state.value.email = (action.payload.email || "").toString();
      state.value.mobile = (action.payload.mobile || "").toString();
      state.value.phone = (action.payload.phone || "").toString();
    },
    setUserOffice: (state, action) => {
      state.value.offices = action.payload.offices || [];
    },
    setUserPermission: (state, action) => {
      state.value.permissions = action.payload.permissions || [];
    },
    setToken: (state, action) => {
      state.value.token = action.payload.token || "";
    },
    clearUserData: (state, action) => {
      state.value = {
        sub: "",
        name: "",
        permissions: [],
        offices: [],
        email: "",
        mobile: "",
        phone: "",
        officeOptions : [],
        permissionOptions : []
      };
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserData, setUserOffice, setUserPermission, setToken, clearUserData} = userDataSlice.actions
export default userDataSlice.reducer