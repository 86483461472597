import { createSlice } from '@reduxjs/toolkit'

export const viewNextDeployDaySlice = createSlice({
  name: 'viewNextDeployDay',
  initialState: {
    status: false,
    forwardDisabled: ''
  },
  reducers: {
    setViewNextDeployDay: (state, action) => {
      state.status =  action.payload;
      if (action.payload)
        state.forwardDisabled = 'disabled'
      else
        state.forwardDisabled = ''
    },
    setforwardDisabled: (state, action) => {
      state.forwardDisabled = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setViewNextDeployDay, setforwardDisabled } = viewNextDeployDaySlice.actions
export default viewNextDeployDaySlice.reducer