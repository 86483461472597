import Settings from "../configs/Settings";

const config = {};
export default config;

function load() {
    return fetch(Settings.ApiUrl + '/home/GetSettings')
    .then(result =>  {
        return result.json();
    })
}
export {load}

