import { createSlice } from '@reduxjs/toolkit'

export const solicitationListSlice = createSlice({
  name: 'solicitationList',
  initialState: {
    value: [],
    searchFilter: ""
  },
  reducers: {
    loadSolicitationList: (state, action) => {
      state.value = action.payload || [];
    },
    remove: (state, action) => {
      let solicitationList = state.value;
      let id = action.payload.id || 0;
      let systemOwner = action.payload.systemOwner || "";

      if (!id || !systemOwner) {
        return;
      }
      let newList = solicitationList.filter(s => s.id.toString() != id.toString() && s.systemOwner != systemOwner);
      console.log(solicitationList.length + ' to ' + newList.length)

      state.value = newList;
    },

    addOrUpdate: (state, action) => {
      let solicitationList = state.value;
      let payload = action.payload || {};
      let id = payload.id;
      let systemOwner = payload.systemOwner;

      if (!id || !systemOwner) {
        return;
      }

      let solicitation = solicitationList.find(s => s.id.toString() === id.toString() && s.systemOwner == systemOwner);

      if (!solicitation) {
        solicitationList = [
          payload,
          ...solicitationList
        ];
      } else {
        solicitation = {
          ...solicitation,
          ...payload
        }
      }

      state.value = solicitationList;
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = action.payload || "";
    },
    setFeedback: (state, action) => {
      if (Array.isArray(action.payload))
        action.payload = action.payload[0]
      
      let id = action.payload.id || 0;
      let systemOwner = action.payload.systemOwner || "";
      let feedbackRating = action.payload.feedbackRating || 0;
      let feedbackAt = action.payload.feedbackAt || "";
      let feedbackSei = action.payload.feedbackSei || "";
      let manifestationAt = action.payload.manifestationAt || "";
      let solicitationList = state.value;
      let solicitation = solicitationList.find(s => s.id.toString() === id.toString() && s.systemOwner == systemOwner);

      if(!solicitation){
        return;
      }

      if(!solicitation.feedback && action.payload.feedback){
        solicitation.feedback = action.payload.feedback;
      }
      
      switch(systemOwner){
        case "GSEC": 
        case "ScheduleCentral":
          solicitation.feedbackRating = feedbackRating;
          solicitation.feedbackAt = feedbackAt;
          solicitation.feedbackSei = feedbackSei;
          solicitation.manifestationAt = manifestationAt;
          break;
        case "Ouvidoria":
          solicitation.feedback = action.payload.feedback;
          break;
        default:
          solicitation.feedback.feedbackRating = action.payload.feedback.feedbackRating;
          solicitation.feedback.feedbackAt = action.payload.feedback.feedbackAt;
          solicitation.feedback.feedbackSei = action.payload.feedback.feedbackSei;
          solicitation.feedback.manifestationAt = action.payload.feedback.manifestationAt;
      }
      state.value = solicitationList;
    }
  },
})

// Action creators are generated for each case reducer function
export const { addOrUpdate, remove, loadSolicitationList, setSearchFilter, setFeedback } = solicitationListSlice.actions

export default solicitationListSlice.reducer