import { createSlice } from '@reduxjs/toolkit'

export const toastBoxSlice = createSlice({
  name: 'toastBox',
  initialState: {
    status: "not-show"
  },
  reducers: {
    setToastBox: (state, action) => {
      state.status =  action.payload || '';
    },
  },
})

// Action creators are generated for each case reducer function
export const { setToastBox } = toastBoxSlice.actions
export default toastBoxSlice.reducer