import React, {useEffect, useState} from 'react';
import {load} from "./config";
import { useDispatch } from 'react-redux';
import { setToastBox } from '../data/toastBoxSlice';
import { loadsettings } from '../data/settingsSlice';
import Loader from './Loader';

const ConfigLoader = (props) => {
    // load config from endpoint
    const [isLoaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!isLoaded) {
            dispatch(loadsettings());
            load().then(config => {
                config.loaded = true;
                dispatch(loadsettings(config));
                setLoaded(true);
            }).catch(err => {
                dispatch(setToastBox("show"));
            });
        }
    }, [dispatch, isLoaded])

    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if(!isLoaded) {
        return(<Loader useToast={true}></Loader>);
    }

    return (
        <>
            {props.children}
        </>
    );

}

export default ConfigLoader;