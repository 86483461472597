import React from 'react';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthenticationProvider, oidcLog, InMemoryWebStorage } from '@axa-fr/react-oidc-context';
import { useSelector, useDispatch } from 'react-redux';
import GA4React from 'ga-4-react';
import OidcSettings from './configs/Oidcsettings';
import Settings from './configs/Settings';
import CustomCallback from './components/OauthCallbacks/CustomCallback';
import CustomOffLineCallback from './components/OauthCallbacks/CustomOffLineCallback';
import ConfigLoader from './components/ConfigLoader';
import EnvironmentAlert from './components/EnvironmentAlert';

import Routes from './configs/Routes';
import './assets/css/style.css';
import { setToken, setUserData } from './data/userDataSlice';

const history = createBrowserHistory();
const ga4react = new GA4React(Settings.googleAnalyticsId);

ga4react.initialize().then((ga4 => {
  ga4.pageview(window.location.pathname + window.location.search);

  history.listen((location) => {
    let url = location.pathname + location.search;
    try {
      ga4.pageview(url);
    } catch (error) {
      console.log('GA failed');
    }
  });
})).catch(err => console.log(err));

const App = () => {
  const settings = useSelector(state => state.settings.value);
  const oidcSettings = OidcSettings(settings);
  const dispatch = useDispatch();
  const onUserSignedOutCallback = () => {
    console.log("onUserSignedOut");
    const url = oidcSettings.authority+"/Account/Logout?showLogoutPrompt=False&postLogoutRedirectUri="+Settings.FullBaseUrl;
    window.location.replace(url);
  }

  const onUserLoadedCallback = (user) => {
    dispatch(setToken({ token: `Bearer ${user.access_token}` }));
    dispatch(setUserData({
      sub: user.profile.sub,
      name: user.profile.name,
      email: user.profile.Email,
      mobile: user.profile.Mobile,
      phone: user.profile.Phone
     }));
  }

  return (<>
      <EnvironmentAlert></EnvironmentAlert>
      <ConfigLoader>
      <div id="appCapsule" className='container' style={{
          minHeight: '80vh'
        }}>
          <Router history={history}>
            <AuthenticationProvider
              configuration={oidcSettings}
              loggerLevel={oidcLog.ERROR}
              isEnabled={true}
              UserStore={InMemoryWebStorage}
              sessionLostComponent={CustomOffLineCallback}
              notAuthenticated={CustomOffLineCallback}
              notAuthorized={CustomOffLineCallback}
              authenticating={CustomCallback}
              callbackComponentOverride={CustomCallback}
              customEvents={{
                onUserSignedOut: onUserSignedOutCallback,
                onUserLoaded: onUserLoadedCallback
              }}
            >
              <Routes></Routes>
            </AuthenticationProvider>
          </Router>
        </div>
      </ConfigLoader>
    </>
  );
}

export default App;


