import { createSlice } from '@reduxjs/toolkit'

export const neighborhoodListSlice = createSlice({
  name: 'neighborhoodList',
  initialState: {
    value: [],
    available: [],
    reverseGeocoded: {}
  },
  reducers: {
    loadNeighborhoodList: (state, action) => {
      let results = action.payload || [];
      results.forEach(element => {
        element.value = element.id;
        element.label = element.name;
      });

      state.value = results;
    },

    setAvailableNeighborhoods: (state, action) => {
      let results = action.payload || state.value;
      state.available = results;
    },

    setReverseGeocoded: (state, action) => {
      let reverseGeocoded = action.payload && action.payload.reverseGeocoded;
      let results =  reverseGeocoded || {};
      state.reverseGeocoded = results;
    }
  },
})

// Action creators are generated for each case reducer function
export const { loadNeighborhoodList, setReverseGeocoded, setAvailableNeighborhoods } = neighborhoodListSlice.actions

export default neighborhoodListSlice.reducer