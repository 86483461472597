const OidcSettings = (props) => {
    const baseUrl = window.location.origin;
    return {
        authority: props.authority || "",
        client_id: props.clientId || "",
        redirect_uri: baseUrl+'/authentication/callback',
        response_type: 'code',
        post_logout_redirect_uri: baseUrl+'/',
        scope: 'openid profile email',
        silent_redirect_uri: baseUrl+'/authentication/silent_callback',
        automaticSilentRenew: true,
        loadUserInfo: true,
        checkSessionInterval: 60,
    }
};
export default OidcSettings;