import { createSlice } from '@reduxjs/toolkit'

export const postDialogSlice = createSlice({
  name: 'postDialog',
  initialState: {
    status: "notSent",
    display: "",
    message: {
      waiting: "",
      success: "",
      warning: "",
      error: "",
    }
  },
  reducers: {
    setPostDialog: (state, action) => {
        state.status = action.payload || '';
        state.display = "";
        state.message = {
            waiting: "",
            success: "",
            warning: "",
            error: "",
        }
    },
      setPostDialogDisplay: (state, action) => {
        let message = action.payload.message || {};
        state.status = action.payload.status || '';
        state.display = action.payload.display || '';
        state.message = {
            waiting: message.waiting || "",
            success: message.success || "",
            warning: message.warning || "",
            error: message.error || "",
        }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPostDialog, setPostDialogDisplay } = postDialogSlice.actions
export default postDialogSlice.reducer
