import { configureStore } from '@reduxjs/toolkit'
import deviceKeyReducer from './deviceKeySlice'
import loadingOverlayReducer from './loadingOverlaySlice'
import messageListReducer from './messageListSlice'
import neighborhoodListReducer from './neighborhoodListSlice'
import newSolicitationReducer from './newSolicitationSlice'
import postDialogReducer from './postDialogSlice'
import toastBoxReducer from './toastBoxSlice'
import settingsReducer from './settingsSlice'
import solicitationListReducer from './solicitationListSlice'
import streetListReducer from './streetListSlice'
import userDataReducer from './userDataSlice'
import viewNextDeployDayReducer from './viewNextDeployDaySlice'

export default configureStore({
  reducer: {
    deviceKey: deviceKeyReducer,
    userData: userDataReducer,
    loadingOverlay: loadingOverlayReducer,
    toastBox:toastBoxReducer,
    messageList: messageListReducer,
    neighborhoodList: neighborhoodListReducer,
    newSolicitation: newSolicitationReducer,
    postDialog: postDialogReducer,
    settings: settingsReducer,
    solicitationList: solicitationListReducer,
    streetList: streetListReducer,
    viewNextDeployDay: viewNextDeployDayReducer,
  }
})