import { createSlice } from '@reduxjs/toolkit'

export const messageListSlice = createSlice({
  name: 'messageList',
  initialState: {
    value: [],
  },
  reducers: {
    loadMessageList: (state, action) => {
      state.value = action.payload || [];
    },
    userReadMessage: (state, action) => {
      if(action.payload && action.payload.id) {
        var message = state.value.find(m => m.id === action.payload.id);
        message.userRead = true;
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { loadMessageList, userReadMessage } = messageListSlice.actions

export default messageListSlice.reducer