import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withOidcSecure, useReactOidc } from '@axa-fr/react-oidc-context';
import Loader from '../components/Loader';
import moment from 'moment';

const InitialActivity = lazy(() => import('../activities/InitialActivity'));
const SolicitationActivity = lazy(() => import('../activities/SolicitationActivity'));
const RequestDetails = lazy(() => import('../activities/RequestDetails'));
const ManifestationDetails = lazy(() => import('../activities/ManifestationDetails'));
const UserActivity = lazy(() => import('../activities/UserActivity'));
const SquareActivity = lazy(() => import('../activities/SquareActivity'));
const PreserveSquareActivity = lazy(() => import('../activities/PreserveSquareActivity'));
const InstallAppActivity = lazy(() => import('../activities/InstallAppActivity'));
const MessageActivity = lazy(() => import('../activities/MessageActivity'));
const PalliativeOmbudsmanActivity = lazy(() => import('../activities/PalliativeOmbudsmanActivity'));
const AppointmentDetails = lazy(() => import('../activities/AppointmentDetails'));


const Routes = () => {
    const [loadedSolicitation, setloadedSolicitation] = useState(false);
    const { oidcUser } = useReactOidc();
    const [itsTime, setItsTime] = useState(null);
    
    const componentToUser = oidcUser && oidcUser.profile && (oidcUser.profile.sub[0] == 'u' || itsTime) ? withOidcSecure(SolicitationActivity) : InstallAppActivity; 

    useEffect(() => {
        const isTheTime =  moment(new Date()).format('YYYY-MM-DD HH:mm:ss') >= '2023-02-27 11:59:00';
        setItsTime(isTheTime);

        if (!loadedSolicitation) {
            setTimeout(() => { setloadedSolicitation(true) }, 500);
        }
    });

    return (
        <Suspense fallback={<Loader type="dots"></Loader>}>
            <Switch>
                <Route path="/request" component={withOidcSecure(SolicitationActivity)} />
                <Route path="/requestDetails/:id" component={withOidcSecure(RequestDetails)} />
                <Route path="/manifestationDetails/:id" component={withOidcSecure(ManifestationDetails)} />
                <Route path="/userDetails" component={withOidcSecure(UserActivity)} />
                <Route path="/voteSquare/:id" component={SquareActivity} />
                <Route path="/preserveSquare/" component={withOidcSecure(PreserveSquareActivity)} />
                <Route path="/installApp" component={InstallAppActivity} />
                <Route path="/palliativeOmbudsman" component={withOidcSecure(PalliativeOmbudsmanActivity)} />
                <Route path="/messages" component={withOidcSecure(MessageActivity)} />
                <Route path="/ouvidoria" component={withOidcSecure(SolicitationActivity)} />
                <Route path="/appointmentHealthUnit" component={componentToUser} />
                <Route path="/appointmentDetails/:id" component={withOidcSecure(AppointmentDetails)} />

                <Route exact path="">
                    <InitialActivity></InitialActivity>
                    {!loadedSolicitation ?
                        (<Suspense fallback={null}>
                            <div hidden={true}>
                                <SolicitationActivity></SolicitationActivity>
                            </div>
                        </Suspense>) :
                        (<></>)
                    }

                </Route>
            </Switch>
        </Suspense>
    );
}

export default Routes;
