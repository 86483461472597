import { createSlice } from '@reduxjs/toolkit'

export const loadingOverlaySlice = createSlice({
  name: 'loadingOverlay',
  initialState: {
    active: false
  },
  reducers: {
    setLoadingOverlay: (state, action) => {
      state.active =  action.payload || '';
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoadingOverlay } = loadingOverlaySlice.actions
export default loadingOverlaySlice.reducer